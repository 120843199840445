
<template>
<v-app>

    <div class="row" style="width:100%;">
        <div class="col-md-6 ladoe" >
            <img src="./../../assets/lg_grupo.png" width="80%" style="margin-top:2%;max-width: 300px">
        </div>
        <div class="col-md-6 ladod">
            
            
            <form ref="form" class="formulario" >
            <span> <h2>Área do Sócio</h2></span> <br/> 
            <hr/>
            <br/>
            <span> <h4>Autenticação</h4></span> <br/> 

            
                
              <v-text-field  v-model="user.username" label="Usuário" placeholder="Usuário"  Required :rules="[v => !!v || 'Campo Obrigatório']"
              filled rounded dense>
              </v-text-field>

              <v-text-field  v-model="user.password" label="Senha" placeholder="Senha"  Required :rules="[v => !!v || 'Campo Obrigatório']"
              filled rounded dense type="password">
              </v-text-field>
             

              <v-btn
                class="mr-4" color="success" @click="handleLogin" block :loading="loading" rounded>
                Confirmar
                          <v-icon right dark>mdi-lock-open</v-icon>
              </v-btn>
              <v-btn style="margin-top:8px"
                 block color="danger" @click="ativarToken"  :loading="loading" rounded>
                Ativar Código
                          <v-icon right dark>mdi-ticket</v-icon>
              </v-btn>
              <v-btn style="margin-top:8px"
                class="mr-6" block color="blue" @click="recuperarSenha"  :loading="loading" rounded>
                Recuperar Senha
                          <v-icon right dark>mdi-lock</v-icon>
              </v-btn>

              
              
            </form>
        </div>


    </div>

    <v-dialog  v-model="alert_token"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Ativar Código de Segurançã
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="codigo_seguranca" style="font-size:20px;" 
                  outlined :rules="nameRules"
                  clearable 
                  label="Código de Ativação"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_token = false"
          >
            Cancelar
          </v-btn>
          <v-btn
          color="primary"
          @click="confirmToken()"
        >
          OK
        </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog  v-model="alert_senha"  width="500">
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Recuperar Senha
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="telefoneRS" style="font-size:20px;" 
              outlined :rules="nameRules" v-mask="'(##) #####-####'"
              clearable 
              label="Telefone Cadastrado"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="alert_senha = false"
      >
        Cancelar
      </v-btn>
      <v-btn :loading="load_rs" 
      color="primary"
      @click="confirmRS()"
    >
      OK
    </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    <v-dialog  v-model="alert_msg"  width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Mensagem
          </v-card-title>

          <v-card-text>
              {{msg_dialog}}
          </v-card-text>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="alert_msg = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
    >
    Usuário e/ou senha inválidos

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>


</v-app>
</template>

<style scoped>

.gradient-background {
  background: linear-gradient(93deg,#d0caca,#d2d1d1);
  background-size: 120% 120%;
  animation: gradient-animation 20s ease infinite;
}

.ladoe{
  background: linear-gradient(93deg,#424242,#9e9e9e);
  text-align:center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ladod{
  text-align:center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.formulario{

  margin-top:2%;
  width:80%;
  max-width: 400px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }

}

</style>

<script>
import User from '../../models/user'
import Usuario from '../../services/usuario'

export default {
    name: 'Login',
    data () {
      return {
        nameRules: [
        v => !!v || 'Campo Obrigatório',
      ],
        snackbar: false,
        alert_token: false,
        alert_senha: false,
        codigo_seguranca: '',
        load_rs: false,
        telefoneRS: '',
        alert_msg: false,
        loading: false,
        user: new User('', ''),
        msg_dialog: '',
        valorRules: [
        v => !!v || 'Campo obrigatório',
        ],
        data: {  
              email: '',
              senha: '',
              ip: '',
              host: '',
              tipoHost: '3',
              versao: '2.3',
        }
      }
    },

    methods: {

      salva(){
          //this.snackbar = true;
          this.$router.push('/index');
      },

      ativarToken(){
        this.alert_token = true;
      },

      recuperarSenha(){
        this.alert_senha = true;
      },

      

      confirmToken(){
          Usuario.ativarToken(this.codigo_seguranca).then(response => {
                  this.alert_msg = true,
                  this.loading = false,
                  this.msg_dialog = response.data.mensagem  
          }).catch(e => {
            this.alert_msg = true,
            this.msg_dialog = "Por favor, tente novamente mais tarde ..." 
          }).finally(() => {
            this.alert_token = false;
            this.codigo_seguranca = '';
          })
        },

        confirmRS(){
          this.load_rs = true,
          Usuario.recuperarSenha(this.telefoneRS).then(response => {
                  this.alert_msg = true,
                  this.loading = false,
                  this.msg_dialog = response.data.mensagem  
          }).catch(e => {
            this.alert_msg = true,
            this.msg_dialog = "Por favor, tente novamente mais tarde ..." 
            this.load_rs = false;

          }).finally(() => {
            this.alert_senha = false;
            this.telefoneRS = '';
            this.load_rs = false;

          })
          },



       handleLogin () {        
        this.loading = true
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            (response) => {
              if(response == 2){
                this.alert_msg = true,
                this.loading = false,
                this.msg_dialog = 'Por Favor, Ative seu código de acesso.'  
                
              }else{
                this.showload = true;
                this.$router.push('/dashboard')
                this.loading = false
              }
            },
            error => {
              this.alert_msg = true,
              this.loading = false,
              this.msg_dialog = 'Usuário ou senha não confere'
            },
          )
        }
      },


    }
  }
</script>

